export default function useHeader () {
  const route = useRoute();
  const partnerStore = usePartnerStore();
  const localePath = useLocalePath();
  const buyoutStore = useBuyoutStore();

  const isMenuOpen = ref<boolean>(false);
  const isFixed = ref<boolean>(false);
  const pageHeader = ref<HTMLElement | null>(null);

  const partnerLogo = computed<string | undefined>(() => {
    return partnerStore.getBuyoutPartner?.logo;
  });


  function indexLinkClick (indexLink: string) {
    if (route.fullPath === indexLink) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  const indexLink = computed<string>(() => {
    let path;
    const partner = partnerStore.getBuyoutPartner;
    if (partner?.seo_name) {
      const vPartner = partner?.parent?.seo_name || partner?.seo_name;
      path = localePath({ name: "partner", params: { partner: vPartner }, query: { refId: buyoutStore.getBuyoutReferralId() } });
    } else {
      path = localePath({ name: "index", query: { refId: buyoutStore.getBuyoutReferralId() } });
    }

    return path;
  });

  const buyoutLink = computed<string>(() => {
    const partner = partnerStore?.getBuyoutPartner;

    if (partner?.use_new_device_process) {
      const bonus_partner = partner?.parent?.seo_name || partner?.seo_name;
      return localePath({ name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality", params: {bonus_partner: bonus_partner }, query: { refId: buyoutStore.getBuyoutReferralId() }});
    }
    return localePath({ name: "buyout-type-manufacturer-meta_master-variant-color-quality", query: { refId: partnerStore?.partner?.reference_code } });
  });

  function headerIconAction (modifier: string) {
    if (document.querySelector(`.header__mobileActions .iconWrapper--${modifier}.v-btn--active`)) {
      document.querySelector(".pMenu")?.classList.toggle("is-open");
    }
  }

  function redirectAction(direction: string) {
    isMenuOpen.value = false;
    const partner_seo_name = partnerStore?.getBuyoutPartner?.seo_name || route.params.partner;

    if (document.querySelector(`.new-${direction}`)) {
      useGoTo(`.new-${direction}`, { offset: -100 });
    } else {
      navigateTo(localePath({ name: "partner", params: { partner: partner_seo_name}, query: { to: direction }}));
    }
  }


  return {
    partnerLogo,
    indexLinkClick,
    isMenuOpen,
    isFixed,
    pageHeader,
    indexLink,
    buyoutLink,
    headerIconAction,
    redirectAction
  };
}
